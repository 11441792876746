import React from "react"
import { Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/VeriottLogo.png"

const MPage = () => (
  <Layout>
    <SEO title="Welcome" />
    <div className="intro">
      <img className="veriottLogo" src={logo} alt="veriott logo"></img>
    </div>

    <div>
    <h2 style={{marginBottom:"0.3em",}}>Schedule</h2>
      <p style={{fontWeight: 400,marginBottom:"3em"}}>Here's a rough outline of the posting schedule, but we're <u>always open to suggestions</u> and to optimize it for your audience 🔥</p>

      <h3 style={{marginBottom:"0.3em",}}>
        Day 1 - Arrival</h3>
      <p>
        Upload video of the packaging/ short unboxing to your story
      </p>

      <h3 style={{marginBottom:"0.3em",}}>
        Day 2 - Storytime
      </h3><p>
          Short story where you show off the product and talk about what you like and why you like it 
      </p>

      <h3 style={{marginBottom:"0.3em",}}>
        Day 3 - Promopost
      </h3><p>
          Post photo of you and our product, tag us in the picture, and use our promocode we give you to <b>give your fans a great deal!</b>
      </p>
    </div>
  </Layout>
)

export default MPage
